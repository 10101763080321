import Learning from "../components/learning/Learning";
import Footer from "../components/Footer";
import React from "react";

const LearningPage: React.FC = () => {
  return (
    <>
      <Learning />
      <Footer />
    </>
  );
};

export default LearningPage;
